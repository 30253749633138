<template>
	<div>
		<h2>Завершите оформление</h2>

		<div id="dealInfo">
			<p>Общая информация по договору</p>
			<div>
				<div>
					<span>Вы получите</span>
					<p>{{ getSelectedDeal.Amount | money }} ₽</p>
				</div>
				<div>
					<span>Дата платежа</span>
					<p>{{ getSelectedDeal.payDay }}</p>
				</div>
				<div>
					<span>Сумма платежа</span>
					<p>{{ getSelectedDeal.OutPayments | money }}</p>
				</div>
			</div>
		</div>

		<div id="smsConf">
			<p>
				Мы отправили код для подтверждения согласий и подписания условий
				займа<br />
				на ваш номер <b>{{ phoneVuex }}</b>
			</p>
			<formInput :refs="'pdlSmsConfCode'"></formInput>
			<p>
				<a v-on:click="reSendCode" v-bind:class="{ canClick: timerCount <= 0 }"
					>Повторно отправить код можно будет</a
				>
				через {{ timerCount }} сек
			</p>
		</div>

<!--		<div id="completeRegistration">-->
<!--			<button @click="smsCheck">ЗАВЕРШИТЬ ОФОРМЛЕНИЕ</button>-->
<!--		</div>-->

		<div id="main_ul">
			<div class="flex">
				<span
					>Проставляя уникальный код я подтверждаю, что в отношениях с ООО МКК
					«ФИНМОЛЛ» действую самостоятельно в своих интересах, ознакомлен и
					полностью согласен с условиями документов указанных ниже, мои действия
					не контролируются иными третьими лицами, также подтверждаю что я не
					являюсь публичным должностным лицом.</span
				>
			</div>

			<div id="acceptAll" v-on:click="selectAll">
				<div id="rail">
					<div id="knob" v-bind:class="{ allPointsAccepted: acceptAll }"></div>
				</div>
			</div>

			<formCheckBox :name="'THIRD_PARTIES'" :trueValue="true" :falseValue="false"></formCheckBox>

			<div>
				<formCheckBox :name="'ACCEPTANCE_OF_THE_TERMS'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://finmoll.ru/backend/content/documents/microloan_terms_conditions" target="_blank">Подробнее</a>
			</div>
			<div>
				<formCheckBox :name="'AGREEMENT_WITH_THE_RULES'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://finmoll.ru/backend/content/documents/rules_microloans" target="_blank">Подробнее</a>
			</div>
			<div v-if="false">
				<formCheckBox :name="'AGREEMENT_LONGATSIA_RULES'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="" target="_blank"> Подробнее</a>
			</div>
			<div v-if="false">
        <formCheckBox :name="'AGREEMENT_DATA_TRANSMISSION'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://finmoll.ru/backend/content/documents/consent_processing_personal_data" target="_blank">Подробнее</a>
			</div>
			<div>
				<formCheckBox :name="'REQURENT_PAY'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://finmoll.ru/backend/content/documents/agreement_recurring_payments" target="_blank">Открыть документ</a>
			</div>

			<div>
				<formCheckBox :name="'AGREEMENT_KI_REQUEST'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://lnd.msk.finmoll.com/admd/ki" target="_blank"> Подробнее</a>
			</div>

			<div v-if="false">
				<formCheckBox :name="'GENERAL_TERMS_OF_MICROLOAN'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://finmoll.ru/backend/content/documents/microloan_terms_conditions" target="_blank">Открыть документ</a>
			</div>

			<div v-if="false">
				<formCheckBox :name="'RULES_FOR_MICROLOAN'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://finmoll.ru/backend/content/documents/rules_microloans" target="_blank">Открыть документ</a>
			</div>

			<!-- <div>
				<formCheckBox
					:name="'APPLICATION_MICROLOAN'"
					:trueValue="true"
					:falseValue="false"
				></formCheckBox>
				<a href="https://lnd.msk.finmoll.com/admd/pdlloan" target="_blank">Открыть документ</a>
			</div> -->

			<div>
				<formCheckBox :name="'AGREEMENT_IU'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a :href="uiUrl" target="_blank">Открыть документ</a>
			</div>

      <div>
				<formCheckBox :name="'AGREEMENT_PDN'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a :href="pdnUrl_pdl" target="_blank">Открыть документ</a>
			</div>

      <div>
        <formCheckBox :name="'AGREEMENT_RECURENT'" :trueValue="true" :falseValue="false"></formCheckBox>
        <a href="https://finmoll.ru/backend/content/documents/agreement_recurring_payments" target="_blank">Открыть документ</a>
      </div>

			<div v-if="getActiveBoxDeal.baseAmount > 0">
				<formCheckBox :name="'LOAN_WITH_INSURANCE'" :trueValue="true" :falseValue="false"></formCheckBox>
				<a href="https://www.vsk.ru/cms/assets/4843446e-6f06-438e-8cbc-bfa681b13cb8" target="_blank">общие условия страхования</a>
        <a :href="boxStatementUrl" target="_blank">Заявление на услуги</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
	name: 'formScreenSigningLoan',
	components: {
		formInput: () => import('./components/formInput'),
		formCheckBox: () => import('./components/formCheckBox')
	},
	data() {
		return {
			tmpCalcSumm: 0,
			timerCount: 120,
			acceptAll: false,
		};
	},
	methods: {
		async smsCheck() {
			const result = await this.$store.dispatch(
				'repeatLoan/pdlConfirmCodeCheck'
			);
			if (!result) {
				this.$store.dispatch('repeatLoan/fieldError', {
					target: 'pdlSmsConfCode',
					value: 'Некорректный	код'
				});
			} else {
				this.$store.dispatch('repeatLoan/changeCurrentScreen', 'waiting');
			}
		},
		reSendCode() {
			if (this.timerCount > 0) return false;
			this.timerCount = 120;
			this.$axios({
				method: 'post',
				url: process.env.VUE_APP_URL_TRACE,
				data: {
					action: 171,
					session_id: parseInt(sessionStorage.getItem('session_id'), 10),
					token: sessionStorage.getItem('token'),
					ClientId: localStorage.getItem('cid')
				}
			})
				.then((res) => {
					if (res.data !== undefined) {
						if (res.data.result !== undefined) {
							if (res.data.result.sms !== undefined) {
								if (res.data.result.sms.Data !== undefined) {
									localStorage.setItem('smsGuid', res.data.result.sms.Data);
								}
							}
							if (res.data.result.TraceId !== undefined) {
								localStorage.setItem('traceId', res.data.result.TraceId);
							}
						}
					}
				})
				.catch((res) => {
					console.log(res);
				});
		},
		selectAll() {
			this.acceptAll = !this.acceptAll;
			this.$store.commit('repeatLoan/setValuesToCheckbox', this.acceptAll);
		}
	},
	computed: {
		...mapGetters('repeatLoan', ['getSelectedDeal', 'getActiveBoxDeal']),
		...mapState({
			phoneVuex: (state) => state.repeatLoan.sendData.PHONE,
			calcSumm: (state) => state.repeatLoan.sendData.CALC_SUMM,
			LOAN_WITH_INSURANCE: (state) =>
				state.repeatLoan.sendData.LOAN_WITH_INSURANCE,
			sessionId: (state) => state.repeatLoan.tracing.session_id,
			token: (state) => state.repeatLoan.tracing.token,
			uiUrl() { 
        return 'https://lnd.msk.finmoll.com/admd/iu/' + this.sessionId + '/' + this.token
      },
      pdnUrl_pdl(){
        return 'https://lnd.msk.finmoll.com/admd/pdn/' + this.sessionId + '/' + this.token
      },
      boxStatementUrl(){
        return 'https://lnd.msk.finmoll.com/admd/boxStatement/' + this.sessionId + '/' + this.token
      }
		})
	},
	watch: {
		// LOAN_WITH_INSURANCE(newVal) {
		// 	if (newVal) {
		// 		try {
		// 			this.tmpCalcSumm = this.calcSumm;
		// 			if (this.getActiveBoxDeal[0].Amount !== undefined) {
		// 				this.$store.dispatch('repeatLoan/saveSendData', {
		// 					target: 'CALC_SUMM',
		// 					value: parseInt(this.getActiveBoxDeal[0].Amount, 10)
		// 				});
		// 			}
		// 		} catch (e) {
		// 			console.log(e);
		// 		}
		// 	} else {
		// 		if (this.tmpCalcSumm > 0) {
		// 			this.$store.dispatch('repeatLoan/saveSendData', {
		// 				target: 'CALC_SUMM',
		// 				value: this.tmpCalcSumm
		// 			});
		// 		}
		// 	}
		// },
		timerCount: {
			handler(value) {
				if (value > 0) {
					setTimeout(() => {
						this.timerCount--;
					}, 1000);
				}
			},
			immediate: true
		}
	},
	mounted() {
		this.selectAll();
		if (this.getActiveBoxDeal.baseAmount > 0) {
			this.$store.dispatch('repeatLoan/saveSendData', {
				target: 'LOAN_WITH_INSURANCE',
				value: true
			});
		}
	}
};
</script>

<style scoped>
h2 {text-align: center}
p {  }
input { width: 8.24vw; height: 8.24vw; margin-right: 2.65vw }
button { margin-bottom: 7.35vw; width: 67.94vw; height: 14.71vw; border-radius: 2.94vw }
#dealInfo {background: white;width: 74vw; padding: 1vw 7.06vw 4.71vw 7.06vw; border-radius: 5px; box-shadow: 0 0.88vw 5.59vw 0 rgba(0, 0, 0, 0.10); margin: 0 auto 8.82vw}
#dealInfo > p { text-align: center; font-family: 'Lato-Bold'; margin-bottom: 4.41vw }
#dealInfo > div {display: flex;align-items: center;justify-content: space-around }
#dealInfo > div > div {width: 30%;text-align: center}
#dealInfo > div > div > span { color: #828282; display: inline-block; margin-bottom: 3.82vw }
#dealInfo > div > div > p { }
#main_ul{border-left: 1px solid white;border-bottom: 1px solid white;transition: 0.3s ease-out;border-bottom-left-radius:0.5rem}
#main_ul.hover_all{border-left: 1px solid black;border-bottom: 1px solid black;}
#main_ul.error_all{border-left: 1px solid red;border-bottom: 1px solid red;}
/*#main_ul div:first-child{border-left: 1px solid white!important;border-bottom: 1px solid white!important;}*/
#main_ul > div{ display: flex; margin-bottom: 3.53vw }
#main_ul > div > span{ }
#main_ul > div:hover{}
#main_ul > div > p{ color: #959595; }
#main_ul > div > a{width: 100% }
#main_ul > div > div > p { }
#main_ul > div > div > a { }
.variant { display: flex; flex-direction: column; }
.variant > div { display: flex; align-items: center; }
.variant > div > p { color: #959595; }
#completeRegistration { display: flex; align-items: center }
#acceptAll #rail { position: relative; background: #0C5A44; border-radius: 29.12vw; width: 10vw; height: 3.82vw; display: flex; align-items: center }
#acceptAll #rail .allPointsAccepted { left: 4vw }
#acceptAll #knob { position: absolute; background: #0C5A44; border-radius: 29.12vw; width: 5.88vw; height: 5.88vw; left: 0; transition: ease-out 0.3s; cursor: pointer }
.canClick { color: #45b537;text-decoration: underline;cursor: pointer }
#smsConf > p { color: #959595; margin-bottom: 6.52vw  }
@media (min-width: 760px) {
  h2 { margin-bottom: 2.58vw }
  p { }
  #dealInfo { padding: 0.40vw 1.94vw 2.02vw 1.94vw; width: 31.26vw; margin: 0 0 3.47vw 0 }
  #dealInfo > p { margin-bottom: 1.85vw }
  #dealInfo > div > div > span { margin-bottom: 1.05vw }
  #dealInfo > div > div > p { }
  #smsConf > p { color: #959595; margin-bottom: 1.69vw  }
  #completeRegistration { display: none }
  #main_ul { margin-bottom: 13.39vw }
  #main_ul > div{ margin-bottom: 1.29vw; display: flex; flex-direction: column; }
  #main_ul > div > a { width: auto }
  #main_ul > div > span { }
  #main_ul > div > p { }
  #acceptAll #rail { width: 2.74vw; height: 1.13vw; background: rgba(12, 90, 68, 0.23); border-radius: 17px; opacity: unset}
  #acceptAll #rail .allPointsAccepted { left: 1.2vw }
  #acceptAll #knob { width: 1.61vw; height: 1.61vw; border-radius: 10px; background: #0C5A44; }
  input { width: 2.26vw; height: 2.26vw; margin-right: 0.73vw }
}
@media (min-width: 1240px) {
  h2 { margin-bottom: 2.58vw }
  p { }
  #dealInfo { padding: 5px 29px 25px 29px; width: 31.26vw; margin: 0 0 45px 0 }
  #dealInfo > p { margin-bottom: 1.85vw }
  #dealInfo > div > div > span { margin-bottom: 13px }
  #dealInfo > div > div > p { }
  #smsConf > p { margin-bottom: 21px  }
  #completeRegistration { display: none }
  #main_ul { margin-bottom: 0px }
  #main_ul > div{ margin-bottom: 16px }
  #main_ul > div > span { }
  #main_ul > div > p { }
  #main_ul > div > a { }
  #acceptAll #rail { width: 34px; height: 14px; border-radius: 17px }
  #acceptAll #rail .allPointsAccepted { left: 15px }
  #acceptAll #knob { width: 20px; height: 20px; border-radius: 10px; background: #0C5A44; }
  input { width: 28px; height: 28px; margin-right: 9px }
  .canClick { }
}
</style>
